import React from "react";
import { Link } from "react-router-dom";

function OurDivorceServices() {
    return (
        <div className="content_wrapper container ul_icon">
            <h1 className="content-title">Our Divorce Services</h1>
            <p class="highlighted"><a href="/managed-divorce.html">Managed Divorce</a></p>
            <p>At Managed Divorce UK, we don't rely on 'trained legal assistants' or 'paralegals'. We understand the importance of a good solicitor's training and experience. That's why we use an independent panel of <b>solicitors</b> to <b>oversee every managed divorce for us</b>.</p>
            <p class="text-right">
                <Link to="/managed-divorce.html" title="Read More">Read More</Link>
            </p>
            <div class="divider"></div>
            <p class="highlighted"><a href="/managed-divorce-plus.html">Managed Divorce Plus</a></p>
            <p>Did you know that if you receive a substantial amount of money after you get divorced your ex-partner would still be <b>entitled to at least half of it!</b> Even if you are already divorced, or you already have a separation agreement in place, you must still obtain a Clean Break Order to <b>legally enforce</b> the terms of the agreement.</p>
            <p>Our <b>Managed Divorce Plus Service</b> includes the benefits of our <b>Managed Divorce Service PLUS a solicitor drafted</b> clean break agreement AND a <b>solicitor drafted</b> Will.</p>
            <p class="text-right">
                <Link to="/managed-divorce-plus.html" title="Read More">Read More</Link>
            </p>
            <div class="divider"></div>
        </div>
    );
}

export default OurDivorceServices;