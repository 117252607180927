import React from "react";
import {Link} from "react-router-dom";
import TestimonialsSlider from "../../components/TestimonialsSlider";
import houseIcon from "../../images/house-icon.svg";
import printIcon from "../../images/email-icon.svg";
import callIcon from "../../images/phone-icon.svg";
import Faq from "react-faq-component";
import RequestFrom from "../../components/RequestFrom";

const data = {
    title: "",
    rows: [
        {
            title: "Are there any differences between Managed Divorce UK and a High Street Solicitor?",
            content: <p>The only real differences are cost and time. You save an average of £750 pounds by using Managed Divorce UK and will have your divorce completed much quicker than a High Street solicitor.</p>,
        },
        {
            title: "Why are you so cheap?",
            content: <p>We are an expert online divorce service – therefore we have considerably fewer overheads than a high street solicitor – this keeps our costs down to a minimum, allowing us to pass on our savings to our customers.</p>,
        },
        {
            title: "Will I have my own Case Manager?",
            content: <p>Yes. All case managers are carefully selected to match your specific circumstances and are dedicated to providing you with expert advice and support throughout the entire process.</p>,
        },
        {
            title: "When can I contact my Case Manager?",
            content: <p>Your Case Manager is available Monday to Friday between the hours of 8am and 5.30pm. Additional support is also available until 9pm Monday to Thursday.</p>,
        },
        {
            title: "What is a Clean Break Order?",
            content: <p>The “Clean Break” Consent Order (Usual cost £100) allows you to ensure that, following your divorce; your ex-partner cannot go back to court to ask for more money or assets. Your 100% free Clean Break Order is the only way that you can guarantee that any agreement you and your ex-partner may reach is enforceable by law and that no further claims can be made.<br/>There will always be a risk that either party can claim after the divorce if the court does not make a financial order. So even if you have already divided your assets or have very little assets, it makes sense to have the insurance that there can be no further claims in the future.<br/>Your order is drafted using the latest court approved precedents by our experienced legal drafting team.<br/>We do all the legal drafting and filing for you so all you have to do is sign the documents when we send them to you. We deal with the court on your behalf throughout to ensure you receive a first class service.</p>,
        },
    ],
};

const config = {
    animate: true,
    arrowIcon: '',
    tabFocus: true
};

function Home() {

    return (
        
      <div className="content_wrapper index">
        <div className="about_managed_divorce container clearfix">
            <div className="about_managed_divorce_left">
                <h1><Link to="/our-experience-and-expertise.html">The UK's Largest Divorce Provider</Link></h1>
                {/* <p data-tip="hello world">Tooltip</p> */}
                <p>Welcome to Managed Divorce, the UK’s largest provider of online divorce solutions. During the past 12 years we have processed over 200,000 divorces and established ourselves as one of the most trusted and stable online divorce providers operating in the UK today.</p>
                <div className="home_divider"></div>
                <h3><Link to="/quick-simple-stress-free.html">Quick, Simple &amp; Stress Free</Link></h3>
                <p>Managed Divorce can help you make your divorce as painless as possible and remove the frustration of completing a stream of long and confusing forms and all without the cost of a high street solicitor.</p>
                <div className="home_divider"></div>
                <h3><Link to="/unrivalled-service.html">Unrivalled Service</Link></h3>
                <p>Managed Divorce offer an industry-leading money back guarantee ensuring that if you find a cheaper, better quality divorce product within 30 days of purchase, we’ll give you your money back.</p>
                <p>No other online divorce provider completes more divorces, generating as many satisfied customers as Managed Divorce - Guaranteed!</p>
                <div className="home_divider"></div>
                <div className="bottom_box">
                    <p>Managed Divorce UK is dedicated to providing the quickest and most cost effective divorce solutions and provides its services for uncontested divorces in England and Wales only.</p>
                </div>
            </div>
            <div className="about_managed_divorce_right home_form">
                <RequestFrom /> 
            </div>
        </div>
        <div className="premier_Service_home">
            <div className="container clearfix">
                <h2><Link to="/3-step-process.html">Premier Service</Link></h2>
                <p>Our premier service offers no complex forms to fill in, no online questionnaires, no hassle, and no fuss. You don’t even have to post the forms, we collect them from your door!</p>
                <div className="premier_Service_home_inner">
                    <div className="premier_Service_home_inner_item">
                        <div className="premier_Service_home_inner_img">
                            <img src={callIcon} alt="Call" title="Call"/>
                        </div>
                        <p>After ordering our service, just answer some simple questions and we will draft the documentation and email it to you usually within an hour.</p>
                    </div>
                    <div className="premier_Service_home_inner_item">
                        <div className="premier_Service_home_inner_img">
                            <img src={printIcon} alt="Print" title="Print"/>
                        </div>
                        <p>On receipt of the email, just download, print and sign. Simply put the forms in an envelope, along with your marriage certificate.</p>
                    </div>
                    <div className="premier_Service_home_inner_item">
                        <div className="premier_Service_home_inner_img">
                            <img src={houseIcon} alt="Home" title="Home"/>
                        </div>
                        <p>Just wait for the courier to arrive at your door and hand him the documentation.</p>
                    </div>
                </div>
            </div>                    
        </div>
        <div className="most_effective_solutions">
            <div className="container clearfix">
                <h2><Link to="/cost-effective-solution.html">Most Cost Effective Solution Available</Link></h2>
                <p>Managed Divorce offer the most <strong>Cost Effective</strong> online divorce solution available in the UK today - <strong>GUARANTEED!</strong></p>
                <div className="most_effective_solutions_inner">
                    <div className="most_effective_solutions_inner_item">
                        <h3>Divorce Online</h3>
                        <table>
                            <tbody>
                                <tr>
                                    <td>Forms</td>
                                    <td>-</td>
                                </tr>
                                <tr>
                                    <td>DIY</td>
                                    <td>£69</td>
                                </tr>
                                <tr>
                                    <td>Managed</td>
                                    <td>£193+</td>
                                </tr>
                            </tbody>                            
                        </table>
                    </div>
                    <div className="most_effective_solutions_inner_item">
                        <h3>Fast-Track Divorce</h3>
                        <table>
                            <tbody>
                                <tr>
                                    <td>Forms</td>
                                    <td>£37</td>
                                </tr>
                                <tr>
                                    <td>DIY</td>
                                    <td>£69</td>
                                </tr>
                                <tr>
                                    <td>Managed</td>
                                    <td>£147+</td>
                                </tr>
                            </tbody>                            
                        </table>
                    </div>
                    <div className="most_effective_solutions_inner_item">
                        <h3>The Law Practice</h3>
                        <table>
                            <tbody>
                                <tr>
                                    <td>Forms</td>
                                    <td>£37</td>
                                </tr>
                                <tr>
                                    <td>DIY</td>
                                    <td>£67</td>
                                </tr>
                                <tr>
                                    <td>Managed</td>
                                    <td>£147+</td>
                                </tr>
                            </tbody>                            
                        </table>
                    </div>
                    <div className="most_effective_solutions_inner_item">
                        <h3>Managed Divorce</h3>
                        <table>
                            <tbody>
                                <tr>
                                    <td>Forms</td>
                                    <td>FREE</td>
                                </tr>
                                <tr>
                                    <td>DIY</td>
                                    <td>FREE</td>
                                </tr>
                                <tr>
                                    <td>Managed</td>
                                    <td>£97+</td>
                                </tr>
                            </tbody>                            
                        </table>
                    </div>                    
                </div>
            </div>
        </div>
        <div className="testimonials_slider">
            <TestimonialsSlider />
        </div>
        <div className="faq_home container">
            <h2>Frequantly Asked Questions</h2>
            <Faq
                data={data}
                config={config}
            />
            <div className="faq_buttons_bottom">
                <Link to="/frequently-asked-divorce-questions.html" className="btn" title="">MORE QUESTIONS?</Link>
                <Link to="/contact-us.html" className="btn" title="">GET IN TOUCH!</Link>
            </div>
        </div>
    </div>
    );
}

export default Home;