import React from "react";
import dgImg1 from "../../images/t_00_1.png";
import dgImg2 from "../../images/t_00_2.png";
import dgImg3 from "../../images/t_00_3.png";
import dgImg11 from "../../images/t_01.gif";
import dgImg12 from "../../images/t_02.gif";
import dgImg13 from "../../images/t_03.gif";
import dgImg21 from "../../images/t_04.gif";
import dgImg22 from "../../images/t_05.gif";
import dgImg23 from "../../images/t_06.gif";
import dgImg24 from "../../images/t_07.gif";
import dgImg31 from "../../images/t_08.gif";
import dgImg32 from "../../images/t_09.gif";
import dgImg33 from "../../images/t_10.gif";

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

function DivorceGlance() {
    return (
        <div className="content_wrapper container">
            <h1 className="content-title">DIVORCE AT A GLANCE</h1>
            <div className="divider"></div>
            <div className="dg_col">
                <div className="dg_left">
                    <div className="dg_img_top">
                        <img src={dgImg1} alt="Step 1" title="Step 1"/>
                    </div>
                    <div className="dg_img_bottom">
                        <img src={dgImg11} alt="Step 1" title="Step 1"/>
                        <img src={dgImg12} alt="Step 1" title="Step 1"/>
                        <img src={dgImg13} alt="Step 1" title="Step 1"/>
                    </div>
                </div>
                <div className="dg_right">
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Complete Questionnaire, Part 1
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>Your case manager will call you and complete your divorce petition over the phone. They will then send you the completed forms for you to check.</p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Check and Sign D8
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>Once you have received your completed divorce forms, you should check them thoroughly for any discrepancies and sign them.</p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Post D8
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>You should then post back to Managed Divorce the following:</p>
                                <ol>
                                    <li>Divorce Petition Form <b>D8</b> (including 3 copies).</li>
                                    <li><b>Marriage certificate</b> or duplicate of certificate (NOT a photocopy).</li>
                                    <li>Court fee of <b>£593</b> (unless you are receiving benefit).</li>
                                </ol>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>
                </div>
            </div>
            <div className="dg_col">
                <div className="dg_left">
                    <div className="dg_img_top">
                        <img src={dgImg2} alt="Step 1" title="Step 1"/>
                    </div>
                    <div className="dg_img_bottom">
                        <img src={dgImg21} alt="Step 1" title="Step 1"/>
                        <img src={dgImg22} alt="Step 1" title="Step 1"/>
                        <img src={dgImg23} alt="Step 1" title="Step 1"/>
                        <img src={dgImg24} alt="Step 1" title="Step 1"/>
                    </div>
                </div>
                <div className="dg_right">
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Acknowledgement of Service Letter
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>Within 14 days of sending your Divorce Petition you should receive an <b>Acknowledgement of Service (D10)</b>. This is an acknowledgement that the divorce petition has been served on the Respondent.</p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Complete Questionnaire, Part 2
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>Your case manager will complete the second stage of your application over the phone with you. Once complete, you will receive a copy of the <b>D80</b> and <b>D84</b> forms.</p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Check and Sign D80 and D84
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>Once you have received your completed divorce forms, you should check them thoroughly for any discrepancies and sign them.</p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Post D80 and D84
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>Post the <b>D80</b> and the following documents back to Managed Divorce:</p>
                                <ol>
                                    <li>Application for Directions <b>D84</b></li>
                                    <li>Form <b>D80(a) Adultery</b>, Form <b>D80(b) Unreasonable Behaviour</b>, Form <b>D80(d) 2 Years Separation with Consent</b>, or Form <b>D80(e) 5 Years Without Consent</b>.</li>
                                    <li><b>Form D10 Acknowledgement of Service</b>.</li>
                                </ol>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>
                </div>
            </div>
            <div className="dg_col">
                <div className="dg_left">
                    <div className="dg_img_top">
                        <img src={dgImg3} alt="Step 3" title="Step 3"/>
                    </div>
                    <div className="dg_img_bottom">
                        <img src={dgImg31} alt="Step 3" title="Step 3"/>
                        <img src={dgImg32} alt="Step 3" title="Step 3"/>
                        <img src={dgImg33} alt="Step 3" title="Step 3"/>
                    </div>
                </div>
                <div className="dg_right">
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Receive Decree NISI
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>You will then receive a date on which your <b>Decree NISI</b> will be pronounced in court. The Decree NISI is a court order declaring that your marriage is in the process of being dissolved. You do not have to attend Court - a copy of your Decree NISI will be posted to you.</p>
                                <p>You can update the case tracker with this information once you receive it, or let us know via an email or phone call.</p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Post D36
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>Sign form <b>D36</b> and return it to Managed Divorce.</p>
                                <p>Managed Divorce will file form D36 with the court.</p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Decree Absolute D37
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>The court will send you your divorce certificate, the Decree Absolute, which will confirm your completed divorce.</p>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>
                </div>
            </div>
        </div>
    );
}

export default DivorceGlance;