import React from "react";
import CaseTrackingImg from '../../images/tracker.jpg';

function CaseTracking() {
    return (
        <div className="content_wrapper case_tracking container">
            <h1 className="content-title">Book a Free Callback</h1>
            <p className="highlighted">Your  Details</p>
            <p>Simply complete the form below to book your FREE callback. You will receive a consultation with a Divorce Specialist who will provide you with all the information about filing your divorce.</p>
            <p>The details you provide below will be used to contact you for your callback.</p>
            <div className="booking-block">
                <form name="consultation" method="post" className="">
                    <div className="form-group">
                        <label className="" for="consultation_firstname">First name or names</label>
                        <div className="">
                            <input type="text" id="consultation_firstname" name="consultation[firstname]" className="form-control input-sm" />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="" for="consultation_surname">Surname</label>
                        <div className="">
                            <input type="text" id="consultation_surname" name="consultation[surname]" className="form-control input-sm" />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="" for="consultation_address">Address</label>
                        <div className="">
                            <input type="text" id="consultation_address" name="consultation[address]" className="form-control input-sm" />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="" for="consultation_town">Town / City</label>
                        <div className="">
                            <input type="text" id="consultation_town" name="consultation[town]" className="form-control input-sm" />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="" for="consultation_postcode">Postcode</label>
                        <div className="">
                            <input type="text" id="consultation_postcode" name="consultation[postcode]" className="form-control input-sm" />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="" for="consultation_phone">Phone number</label>
                        <div className="">
                            <input type="text" id="consultation_phone" name="consultation[phone]" className="form-control input-sm" />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="" for="consultation_email">Email Address</label>
                        <div className="">
                            <input type="text" id="consultation_email" name="consultation[email]" className="form-control input-sm" />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="" for="consultation_email_valid">Please confirm your Email Address</label>
                        <div className="">
                            <input type="text" id="consultation_email_valid" name="consultation[email_valid]" className="form-control input-sm" />
                        </div>
                    </div>

                    <p className="text-right margin-top-15">
                        <button type="submit" name="submitted-form" value="consultation" className="btn btn-md-primary">Submit <i className="glyphicon glyphicon-play" aria-hidden="true"></i></button>
                    </p>
                    <input type="hidden" id="consultation_product_ki" name="consultation[product_ki]" value="md_05_con" /><input type="hidden" id="consultation_formName" name="consultation[formName]" value="consultation" />
                </form>
            </div>
        </div>
    );
}

export default CaseTracking;