import React, { useEffect, useState } from "react";
import Faq from "react-faq-component";
import arrowImg from "../../images/divorceBook.gif";

const data = {
    title: "",
    rows: [
        {
            title: "Are there any differences between Managed Divorce UK and a High Street Solicitor?",
            content: <p>The only real differences are cost and time. You save an average of £750 pounds by using Managed Divorce UK and will have your divorce completed much quicker than a High Street solicitor.</p>,
        },
        {
            title: "Why are you so cheap?",
            content: <p>We are an expert online divorce service – therefore we have considerably fewer overheads than a high street solicitor – this keeps our costs down to a minimum, allowing us to pass on our savings to our customers.</p>,
        },
        {
            title: "Will I have my own Case Manager?",
            content: <p>Yes. All case managers are carefully selected to match your specific circumstances and are dedicated to providing you with expert advice and support throughout the entire process.</p>,
        },
        {
            title: "When can I contact my Case Manager?",
            content: <p>Your Case Manager is available Monday to Friday between the hours of 8am and 5.30pm. Additional support is also available until 9pm Monday to Thursday.</p>,
        },
        {
            title: "What is a Clean Break Order?",
            content: <p>The “Clean Break” Consent Order (Usual cost £100) allows you to ensure that, following your divorce; your ex-partner cannot go back to court to ask for more money or assets. Your 100% free Clean Break Order is the only way that you can guarantee that any agreement you and your ex-partner may reach is enforceable by law and that no further claims can be made.<br/>There will always be a risk that either party can claim after the divorce if the court does not make a financial order. So even if you have already divided your assets or have very little assets, it makes sense to have the insurance that there can be no further claims in the future.<br/>Your order is drafted using the latest court approved precedents by our experienced legal drafting team.<br/>We do all the legal drafting and filing for you so all you have to do is sign the documents when we send them to you. We deal with the court on your behalf throughout to ensure you receive a first class service.</p>,
        },
        {
            title: "How long does the divorce process take and what is involved?",
            content: <p>The normal divorce takes between 3 and 4 months. For what is involved have a look at "about divorce" section on this web site or click here to see the process at a glance.</p>,
        },
        {
            title: "What are the likely costs?",
            content: <p>The Court Fees are £593.</p>,
        },
        {
            title: "How do I find my local Divorce Centre?",
            content: <p>We will let you know the location of your closest Divorce Centre.</p>,
        },
        {
            title: "How long do I have to be married before I can get a divorce?",
            content: <p>You cannot start a petition for divorce unless you have been married for more than one year.</p>,
        },
        {
            title: "What are the grounds for a divorce?",
            content: <p>Since 1968 there has been just one ground for divorce – irretrievable breakdown of marriage. However, you have to prove irretrievable breakdown in one of five different ways. These are: adultery, unreasonable behaviour (often described as cruelty), desertion for two years, separation for two years where the other party consents, and separation for five years (no consent needed).</p>,
        },
        {
            title: "How long do I have to wait before an application is made for the Decree NISI to be made absolute?",
            content: <p>6 weeks, one day after this you may apply for the Decree Absolute.</p>,
        },
        {
            title: "If I have been separated for 2 years am I automatically entitled to a divorce?",
            content: <p>No, you would need the other parties consent; alternatively you could rely on the fact of unreasonable behaviour or 5 years Separation whereby consent is not required.</p>,
        },
        {
            title: "What if I don't know the other parties address?",
            content: <p>Attempt to find out from family & friends. Alternatively, a tracing agent may be able to trace the party’s whereabouts.</p>,
        },
        {
            title: "What if the respondent does not return the acknowledgement of service form?",
            content: <p>You would need to request the court to allow the court bailiff to personally serve the divorce documents on the respondent. The court bailiff would then sign a statement confirming service, should the respondent still not respond you proceed to the next stage, the application for special procedure whereby you would use the signed statement as an exhibit. You will have to pay the court a fixed fee for this service.<br/>After service you must allow 21 days for the respondent to file a defence (called an "answer" in divorce proceedings) and, if they do not, you can then apply for the decree NISI (the first divorce decree).<br/>We can provide you with a "Request for Service by Court Bailiff" form.</p>,
        },
        {
            title: "What if the petitioner does not apply for the Decree Absolute, can the respondent apply?",
            content: <p>Yes, providing 3 months have elapsed and the parties have not reconciled.</p>,
        },
        {
            title: "What if I cannot find the original Marriage Certificate?",
            content: <p>You can contact the relevant registry office providing them with the party's names and date of marriage. They will provide you with the original marriage certificate for a small fee (we can obtain this for you as part of our service).</p>,
        },
        {
            title: "What if the Marriage Certificate is in a foreign language?",
            content: <p>In this case the marriage certificate needs to be translated into English by an expert who would then have it sworn. You then need to lodge with the court alongside other documents the original marriage certificate, the translation of the marriage certificate and the experts sworn statement.</p>,
        },
        {
            title: "What if I am on low income or unemployed; do I still have to pay the Court Fee?",
            content: <p>You may be exempt: you would need to complete the fee exemption form which we would provide. The Divorce Centre staff will make an assessment as to whether you qualify.</p>,
        },
        {
            title: "What if the Petitioner (person initiating the divorce) resides in England and Wales and the other party (Respondent) resides abroad; can they still divorce?",
            content: <p>Yes, provided one party is either domiciled or habitually resident (for a year) in England and Wales.</p>,
        },
        {
            title: "Do I have to agree all financial matters before I start divorce proceedings?",
            content: <p>No, you do not - but it is a good idea to do so. If you ignore the financial issues and remarry, you may lose the right to have the matrimonial financial affairs reviewed by the court. Many people do not realise that your ex. can make a claim against your estate after you die. There are special rules, and a claim will not succeed in every case, but it is wise to get an agreement on finances and if appropriate obtain a 'clean break' order (see our divorce and money section).</p>,
        },
        {
            title: "Do I have to agree all arrangements for the children before divorce proceedings?",
            content: <p>Any disputes or arrangements regarding children are entirely separate from the divorce proceedings, and will have no baring on an uncontested divorce.</p>,
        },
        {
            title: "Does it make any difference if we were married abroad?",
            content: <p>No, but if the marriage certificate is in another language then you need to obtain a notarised translation.</p>,
        },
        {
            title: "Does it make any difference if either or both of us are foreign nationals?",
            content: <p>No, provided either of you are habitually resident in England and Wales.<br />There are complicated rules and if you are unsure please e-mail us.</p>,
        },
        {
            title: "Does it make any difference if either of us is resident abroad?",
            content: <p>No, provided the other is habitually resident here.</p>,
        },
        {
            title: "Can I rely on my own adultery for a divorce?",
            content: <p>Afraid not, in this circumstance you will have to ask your spouse to divorce you.</p>,
        },
        {
            title: "If I want to rely on my spouse's adultery after we are separated will it still count?",
            content: <p>Unfortunately not. In order to successfully file for a divorce on the grounds of adultery, then the adultery must be the reason for you and your spouse having separated.</p>,
        },
        {
            title: "Can I still take divorce proceedings on the grounds of adultery or unreasonable behaviour even if we continue living together?",
            content: <p>Yes, provided that whilst living in the same house you have lived completely separate lives. This means that you have slept in separate bedrooms, done your own domestic chores, cook, eat and socialise separately and bills should be divided.</p>,
        },
        {
            title: "If we want to use the ground of separation by consent, do I have to get my spouse's consent before I start the divorce action?",
            content: <p>No, your spouse will be given an opportunity to consent on the acknowledgement form when he/she receives the papers from the court.</p>,
        },
        {
            title: "When will my completed divorce petition be delivered to me?",
            content: <p>Currently, 99.97% of all divorce petitions are returned the same day they are completed. We aim to dispatch your divorce petition the same day, if submitted before 5.30pm Monday to Friday, and guarantee to dispatch your completed divorce petition within 24 hours of submission.</p>,
        },
    ],
};

const config = {
    animate: true,
    arrowIcon: '',
    tabFocus: true
};

function FAQ() {
    return (
        <div className="content_wrapper container">
            <h1 className="content-title">FAQ'S</h1>
            <Faq
                data={data}
                config={config}
            />
        </div>
    );
}
export default FAQ;