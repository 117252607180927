import React from "react";
import Slider from "react-slick";

export default function TestimonialsSlider() {
    var settings = {
      dots: true,
      infinite: true,
      nav: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            adaptiveHeight: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ]
    };
    return (
      <div className="clearfix container">
        <h2>Testimonials</h2>
        <Slider {...settings}>
          <div className="">
            <h3>Rosario Reed - Leicestershire</h3>
            <ul className="">
              <li><a href="" title="Star"></a></li>
              <li><a href="" title="Star"></a></li>
              <li><a href="" title="Star"></a></li>
              <li><a href="" title="Star"></a></li>
              <li><a href="" title="Star"></a></li>
            </ul>
            <p>I am hopeless at finding my way around the Internet and I could not believe how easy this was. We have been meaning to sort out our divorce for ages and this made it all so easy, we wish we had done it ages ago instead of procrastinating!!</p>
          </div>
          <div className="">
            <h3>Kelly Mitchell - Swindon</h3>
            <ul className="">
              <li><a href="" title="Star"></a></li>
              <li><a href="" title="Star"></a></li>
              <li><a href="" title="Star"></a></li>
              <li><a href="" title="Star"></a></li>
              <li><a href="" title="Star"></a></li>
            </ul>
            <p>I found it very quick and simple to use. The responses were quick, and very efficient.</p>
          </div>
          <div className="">
            <h3>Tracy Cooke - Peterborough</h3>
            <ul className="">
              <li><a href="" title="Star"></a></li>
              <li><a href="" title="Star"></a></li>
              <li><a href="" title="Star"></a></li>
              <li><a href="" title="Star"></a></li>
              <li><a href="" title="Star"></a></li>
            </ul>
            <p>Very quick and easy to use. Thoroughly recommended.</p>
          </div>
          <div className="">
            <h3>Rosario Reed - Leicestershire</h3>
            <ul className="">
              <li><a href="" title="Star"></a></li>
              <li><a href="" title="Star"></a></li>
              <li><a href="" title="Star"></a></li>
              <li><a href="" title="Star"></a></li>
              <li><a href="" title="Star"></a></li>
            </ul>
            <p>I am hopeless at finding my way around the Internet and I could not believe how easy this was. We have been meaning to sort out our divorce for ages and this made it all so easy, we wish we had done it ages ago instead of procrastinating!!</p>
          </div>
          <div className="">
            <h3>Kelly Mitchell - Swindon</h3>
            <ul className="">
              <li><a href="" title="Star"></a></li>
              <li><a href="" title="Star"></a></li>
              <li><a href="" title="Star"></a></li>
              <li><a href="" title="Star"></a></li>
              <li><a href="" title="Star"></a></li>
            </ul>
            <p>I found it very quick and simple to use. The responses were quick, and very efficient.</p>
          </div>
          <div className="">
            <h3>Tracy Cooke - Peterborough</h3>
            <ul className="">
              <li><a href="" title="Star"></a></li>
              <li><a href="" title="Star"></a></li>
              <li><a href="" title="Star"></a></li>
              <li><a href="" title="Star"></a></li>
              <li><a href="" title="Star"></a></li>
            </ul>
            <p>Very quick and easy to use. Thoroughly recommended.</p>
          </div>
        </Slider>
      </div>
    );
  }