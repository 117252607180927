import React from "react";
import privacy_policy from "../../resources/downloads/privacy_policy.pdf";
import complaints_procedure from "../../resources/downloads/complaints_procedure.pdf";
import terms_and_conditions from "../../resources/downloads/terms_and_conditions.pdf";
import disclaimer from "../../resources/downloads/disclaimer.pdf";

function Terms() {
    return (
        <div className="content_wrapper container">
            <h1 className="content-title">Terms and  Conditions</h1>
            <p>Please see below for all documentation outlining the terms of our services. Please ensure that you read all documentation thoroughly.</p>
            <table className="table table-bordered-none table-vert-middle">
                <tbody>
                    <tr>
                        <td width="200px"><b>Terms and Conditions</b></td>
                        <td><a className="btn btn-danger" href={terms_and_conditions} title="Download" target="_blank" download>Download</a></td>
                    </tr>
                    <tr>
                        <td><b>Complaints Procedure</b></td>
                        <td><a className="btn btn-danger" href={complaints_procedure} title="Download" target="_blank" download>Download</a></td>
                    </tr>
                    <tr>
                        <td><b>Privacy Policy</b></td>
                        <td><a className="btn btn-danger" href={privacy_policy} title="Download" target="_blank" download>Download</a></td>
                    </tr>
                    <tr>
                        <td><b>Disclaimer</b></td>
                        <td><a className="btn btn-danger" href={disclaimer} title="Download" target="_blank" download>Download</a></td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default Terms;