import React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {Footer, Header} from "./components";
import $ from "jquery";
import './App.css';
import Home from "./containers/Home/Home";
import About from "./containers/About/About";
import Contact from "./containers/Contact/Contact";
import Policy from "./containers/Policy/Policy";
import Sitemap from "./containers/Sitemap/Sitemap";
import Terms from "./containers/Terms/Terms";
import CaseTracking from "./containers/CaseTracking/CaseTracking";
import Resources from "./containers/Resources/Resources";
import WhyChooseUs from "./containers/WhyChooseUs/WhyChooseUs";
import FreeEBooks from './containers/FreeEBooks/FreeEBooks';
import PremierService from './containers/PremierService/PremierService';
import CallBack from './containers/CallBack/CallBack';
import FAQ from './containers/FAQ/FAQ';
import StableOrganisation from './containers/StableOrganisation/StableOrganisation';
import UnrivalledService from './containers/UnrivalledService/UnrivalledService';
import OurExperienceExpertise from './containers/OurExperienceExpertise/OurExperienceExpertise';
import Guarantee from './containers/Guarantee/Guarantee';
import QuickSimpleStressFree from './containers/QuickSimpleStressFree/QuickSimpleStressFree';
import OurDivorceServices from './containers/OurDivorceServices/OurDivorceServices';
import CostEffectiveSolution from './containers/CostEffectiveSolution/CostEffectiveSolution';
import ManagedDivorcePage from './containers/ManagedDivorcePage/ManagedDivorcePage';
import ManagedDivorcePlus from './containers/ManagedDivorcePlus/ManagedDivorcePlus';
import DivorceGlance from './containers/DivorceGlance/DivorceGlance';
import ScrollToTop from './components/ScrollToTop';
import ReactTooltip from 'react-tooltip';


$(document).ready(function () {
$(".menu-toggle").click(function(){
  $(".menu-toggle").toggleClass("menu-toggeled");
  $(".head_menu").toggleClass("open");
});

// $(".home_page_main_full").parents(".content").addClass('home');

$(".head_menu > ul > li a").click(function(){
  $(".menu-toggle").removeClass("menu-toggeled");
  $(".head_menu").removeClass("open");

  // if ($(".content").hasClass("home")) {
  //     $(".content").removeClass('home');
  //     $(".home_page_main_full").parents(".content").addClass('home');
  //     window.location.reload(); 
  // }
  // else {
  //     $(".home_page_main_full").parents(".content").addClass('home');
  // }
  
  // $(".home_page_main_full").parents(".content").addClass('home');
  
  $('html,body').animate({
      
      scrollTop: $(".main_content").offset().top},
      'slow');
});
});

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop/>
        <ReactTooltip />
        <Header/>
        <div className="main-content">
          <Switch>
              <Route path="/" exact component={() => <Home/>}/>
              <Route path="/about.html" exact component={() => <About/>}/>
              <Route path="/contact-us.html" exact component={() => <Contact/>}/>
              <Route path="/policy.html" exact component={() => <Policy/>}/>
              <Route path="/sitemap.html" exact component={() => <Sitemap/>}/>
              <Route path="/terms-and-conditions.html" exact component={() => <Terms/>}/>
              <Route path="/24-7-case-tracking.html" exact component={() => <CaseTracking/>}/>
              <Route path="/resources.html" exact component={() => <Resources/>}/>
              <Route path="/whychooseUs.html" exact component={() => <WhyChooseUs/>}/>
              <Route path="/free-ebooks.html" exact component={() => <FreeEBooks/>}/>
              <Route path="/3-step-process.html" exact component={() => <PremierService/>}/>
              <Route path="/book-consultation.html" exact component={() => <CallBack/>}/>
              <Route path="/frequently-asked-divorce-questions.html" exact component={() => <FAQ/>}/>
              <Route path="/stable-organisation.html" exact component={() => <StableOrganisation/>}/>
              <Route path="/unrivalled-service.html" exact component={() => <UnrivalledService/>}/>
              <Route path="/guarantee.html" exact component={() => <Guarantee/>}/>
              <Route path="/our-experience-and-expertise.html" exact component={() => <OurExperienceExpertise/>}/>
              <Route path="/quick-simple-stress-free.html" exact component={() => <QuickSimpleStressFree/>}/>
              <Route path="/our-divorce-services.html" exact component={() => <OurDivorceServices/>}/>
              <Route path="/cost-effective-solution.html" exact component={() => <CostEffectiveSolution/>}/>
              <Route path="/managed-divorce.html" exact component={() => <ManagedDivorcePage/>}/>
              <Route path="/managed-divorce-plus.html" exact component={() => <ManagedDivorcePlus/>}/>
              <Route path="/divorce-at-a-glance.html" exact component={() => <DivorceGlance/>}/>
          </Switch>
        </div>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
