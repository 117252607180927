import React, { useState } from "react";

function RequestFrom(props) {

    return (
        <div class="main_content_right">
            <div className="main_content_right_inner">
                <h3>Request a Free Callback</h3>
                <form name="enquiry">
                    <input type="text" name="name" placeholder="Enter your fullname" />
                    <input type="email" name="email" placeholder="Enter your email address" />
                    <input type="text" name="number" placeholder="Enter your phone number" />
                    <label class="">Length of marriage</label>
                    <div class="form-group form-radio">
                        <div class="radio-group">                        
                            <label for="lom-5+Yr">
                                <input type="radio" name="" value="5+Yr" id="lom-5+Yr" />
                                <span>5+yr</span>                        
                            </label>
                        </div>
                        <div class="radio-group">                        
                            <label for="lom-1-5yr">
                                <input type="radio" name="" value="1-5yr" id="lom-1-5yr" />
                                <span>1-5yr</span>
                            </label>
                        </div>
                        <div class="radio-group">                        
                            <label for="lom-<1yr">
                                <input type="radio" name="" value="<1yr" id="lom-<1yr" />
                                <span>&lt;1yr</span>
                            </label>
                        </div>
                    </div>
                    <label class="">Where do you live</label>
                    <div class="form-group form-radio">
                        <div class="radio-group">                        
                            <label for="wdyl-uk">
                                <input type="radio" name="" value="uk" id="wdyl-uk" />  
                                <span>UK</span>
                            </label>
                        </div>
                        <div class="radio-group">                        
                            <label for="wdyl-europe">
                                <input type="radio" name="" value="europe" id="wdyl-europe" />  
                                <span>Europe</span>
                            </label>
                        </div>
                        <div class="radio-group">                        
                            <label for="wdyl-other">
                                <input type="radio" name="" value="other" id="wdyl-other" />  
                                <span>Other</span>
                            </label>
                        </div>
                    </div>
                    <label class="">Both agree divorce</label>
                    <div class="form-group form-radio">
                        <div class="radio-group">                        
                            <label for="ba-yes">
                                <input type="radio" name="1" value="yes" id="ba-yes" />                                
                                <span>Yes</span>
                            </label>
                        </div>
                        <div class="radio-group">
                            <label for="ba-no">
                                <input type="radio" name="2" value="yes" id="ba-no" />                                
                                <span>No</span>
                            </label>
                        </div>
                        <div class="radio-group">                        
                            <label for="ba-don't know">
                                <input type="radio" name="3" value="don't know" id="ba-don't know" />                                
                                <span>Don't Know</span>
                            </label>
                        </div>
                    </div>
                    <input type="submit" value="Submit Now" />
                </form>
            </div>
        </div>
    );
}
export default RequestFrom;