import React from "react";
import { Link } from "react-router-dom";
import houseIcon from "../../images/house-icon.svg";
import printIcon from "../../images/email-icon.svg";
import callIcon from "../../images/phone-icon.svg";

function PremierService() {
    return (
        <div className="content_wrapper container">
            <h1 className="content-title">Premier Service</h1>
            <div className="premier_Service_home">
                <div className="container clearfix">
                    <p>Our Premier Service offers the quickest divorce option available in the UK today. There are no online questionnaires, forms to fill in, Court attendance or associated hassle. Just a simple short fact finding telephone call with one of our divorce specialists and we will file your petition with the Court on your behalf.</p>
                    <p>The whole process is short and simple and puts your petition in the hands of the Court using three simple steps;</p>
                    <div className="premier_Service_home_inner">
                        <div className="premier_Service_home_inner_item">
                            <div className="premier_Service_home_inner_img">
                                <img src={callIcon} alt="Call" title="Call"/>
                            </div>
                            <p>After ordering our service, just answer some simple questions and we will draft the documentation and email it to you usually within an hour.</p>
                        </div>
                        <div className="premier_Service_home_inner_item">
                            <div className="premier_Service_home_inner_img">
                                <img src={printIcon} alt="Print" title="Print"/>
                            </div>
                            <p>On receipt of the email, just download, print and sign. Simply put the forms in an envelope, along with your marriage certificate.</p>
                        </div>
                        <div className="premier_Service_home_inner_item">
                            <div className="premier_Service_home_inner_img">
                                <img src={houseIcon} alt="Home" title="Home"/>
                            </div>
                            <p>Just wait for the courier to arrive at your door and hand him the documentation.</p>
                        </div>
                    </div>
                    <div className="divider"></div>
                    <p>Your documents will then be delivered to one of the fastest Divorce Centres in the UK, processing divorce petitions at the time we receive your paperwork.</p>
                    <p className="text-right"><Link to="book-consultation.html" title="Book a FREE consultation now">Book a FREE consultation now</Link></p>
                </div>                    
            </div>
        </div>
    );
}

export default PremierService;