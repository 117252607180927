import React from "react";
import EBookOne from "../../images/divorceBook.gif";
import EBookTwo from "../../images/consentOrdersBook.jpg";

function FreeEBooks() {
    return (
        <div className="content_wrapper container">
            <h1 className="content-title">Free Ebooks</h1>
            <p>This is an exclusive offer from Managed Divorce UK for immediate download to your desktop <b>ABSOLUTELY FREE</b> when you buy any Divorce pack.</p>
            <div className="divider"></div>
            <div className="ebook_col">
                <div className="ebook_left">
                    <p className="highlighted">The Essential Guide to Divorce by Dr Charlotte B Price ©2004</p>
                    <p>'The 7 Golden Rules of Divorce: Follow them or regret it for the rest of your life!'</p>
                    <p>Subjects also covered include:</p>
                    <ul>
                        <li>Essentials of divorce</li>
                        <li>Stages in obtaining a divorce</li>
                        <li>Do it yourself divorce in England and Wales</li>
                        <li>Mediation</li>
                        <li>Financial implications of divorce</li>
                        <li>Financial issues relating to children</li>
                        <li>Dividing debt and assets at divorce</li>
                        <li>Wills and divorce</li>
                        <li>Negative and positive coping</li>
                        <li>Children and divorce</li>
                        <li>How will divorce affect the children?</li>
                        <li>Positive steps you can take after divorce</li>
                        <li>All about stress</li>
                        <li>Moving forward after divorce</li>
                        <li>The divorce is final</li>
                        <li>What do you do now?</li>
                        <li>You can control your future</li>
                        <li>And much, much more…</li>
                    </ul>
                </div>
                <div className="ebook_right">
                    <img src={EBookOne} alt="EBook" title="EBook"/>
                </div>
            </div>
            <div className="divider"></div>
            <div className="ebook_col">
                <div className="ebook_left">
                    <p className="highlighted">The Essential Guide to Consent Orders by Dr Charlotte B Price ©2004</p>
                    <p>This advice-filled guide covers every aspect of a Consent Order, including:</p>
                    <p><em>‘The 6 Golden Rules of a Clean Break: How to Avoid the Hidden Financial Traps!’</em></p>
                    <p>Subjects also covered include:</p>
                    <ul>
                        <li>What Exactly is a Clean Break?</li>
                        <li>Some Vital Points to Consider</li>
                        <li>What can a Clean Break Order include?</li>
                        <li>Advantages and Disadvantages of a Clean Break Order</li>
                        <li>How do I know if I am eligible for a Clean Break?</li>
                        <li>How do Consent Orders Work?</li>
                        <li>Pensions and Inheritance with a Clean Break</li>
                        <li>Offsetting</li>
                        <li>Ending a Relationship</li>
                        <li>Wealthier Couples and the Clean Break</li>
                        <li>Legal Aspects of the Clean Break Order</li>
                        <li>Changes to the Law</li>
                        <li>Ancillary Relief Application</li>
                        <li>And much, much more…</li>
                    </ul>
                </div>
                <div className="ebook_right">
                    <img src={EBookTwo} alt="EBook" title="EBook"/>
                </div>
            </div>
        </div>
    );
}

export default FreeEBooks;