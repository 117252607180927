import React from "react";
import { Link } from "react-router-dom";

function OurExperienceExpertise() {
    return (
        <div className="content_wrapper container ul_icon">
            <h1 className="content-title">The UK's Largest Divorce Provider</h1>
            <p class="highlighted">Managed Divorce UK is the largest provider of online managed divorces in the UK!</p>
            <p>In 2018 we processed over 15,000 divorce petitions, including <b>6,500 managed divorces - more than any other online divorce provider</b>.</p>

            <p>No other company comes close to matching these figures. That's because:</p>
            <ul>
                <li>Managed Divorce has <b>the largest, most experienced</b> online divorce team in the industry*</li>
                <li>Managed Divorce uses the <b>most efficient</b> document processing software in the industry*</li>
                <li>Managed Divorce operates out of the <b>largest, most modern</b> online divorce premises in the UK*</li>
            </ul>
            <p>Our divorce team is headed up by <b>experienced case managers</b>, each one a divorce expert.</p>
            <p>Therefore, as <b>every divorce procedure is supervised by a legal expert</b>, you can have total confidence that every divorce document we send is always accurate and legally correct.</p>
            <p>Additionally, 15,000 instructions a year means that the rest of our divorce team are now <b>unparalleled in experience</b>. The benefit to you is that telephone or email queries are answered by the most knowledgeable staff in the business, and every document is expertly double-checked before its release.</p>
            <p><b>You will not find a quicker divorce provider in the UK!</b></p>
            <p>The tens of thousands of divorce applications processed means that our unique online divorce software is arguably the most efficient and reliable anywhere, allowing us to deliver divorce documentation up to <b>three times faster than our competitors</b>.</p>
            <p>None of our rivals can make this claim!</p>
            <p>Quite simply, Managed Divorce's* experience, expertise and efficiency has made us <b>the most trusted and best known brand in the industry</b> – no wonder we helped to facilitate over 10% of all UK divorces last year.</p>
            <p class="text-right"><Link to="/stable-organisation.html" title="stable-organisation">Put your trust in a stable organisation <i class="glyphicon glyphicon-play"></i></Link></p>
            <p><small>* Managed Divorce UK is a trading title of Quickie Divorce Limited – the largest providers of online divorces in the UK today.</small></p>
        </div>
    );
}

export default OurExperienceExpertise;