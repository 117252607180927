import React from "react";
import CaseTrackingImg from '../../images/tracker.jpg';

function CaseTracking() {
    return (
        <div className="content_wrapper case_tracking container">
            <h1 className="content-title">24/7 Case Management Tracking</h1>
            <p className="highlighted">We Are The Only Managed Divorce Provider with 24/7 Case Management Tracking</p>
            <p>At Managed Divorce, we understand that our clients have jobs and responsibilities and may not be able to contact us during opening hours.</p>
            <p>To help with this, we are utilising a cutting edge case management software package developed by Quickie Divorce UK* that allows clients to logon to our website 24/7 and:</p>
            <ul>
                <li>Track the status of their divorce</li>
                <li>Set up automated emails and text alerts for ‘change of status’ events</li>
                <li>Download copies of completed petitions and documents</li>
                <li>Download copies of letters and emails</li>
                <li>Leave messages for support staff</li>
                <li>View the log file of their divorce to verify what work has been completed</li>
            </ul>
            <p>Since its launch, this service has consistently been cited by our clients as one of the most useful facilities available to them during the divorce process.</p>

            <div className="text-center vertical-margin-30 content-image">
                <img src={CaseTrackingImg} title="Case Tracking" alt="tracker screen-sho" />
            </div>
            <p>No other online divorce provider offers such a comprehensive range of case tracking functionality, because no other online managed divorce provider has invested as much time, effort and money into ensuring that the divorce process is as quick, affordable and stress-free as possible for its clients.</p>
            <p><small>* Managed Divorce UK is a trading title of Quickie Divorce Limited – the largest providers of online divorces in the UK today.</small></p>
        </div>
    );
}

export default CaseTracking;