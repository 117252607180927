import React from "react";
import { Link } from "react-router-dom";

function QuickSimpleStressFree() {
    return (
        <div className="content_wrapper container">
            <h1 class="content-title">Quick, Simple and Stress Free</h1>
            <p class="highlighted">Managed Divorces are Stress-free</p>
            <p>Last year, over 6,500 people in the UK used Managed Divorce UK to conduct their divorce saving up to 90% when compared to the cost of a High Street Law Firm.</p>
            <p>Whether you choose a service where we collect and deliver your documents from 'door to court', or a service where our panel solicitors oversee the whole process we <b>guarantee</b> that we provide the quickest and simplest solutions.</p>
            <p>That's why <b>all</b> of our services come with a <b>'no forms and no court promise'</b> because we will <b>handle all the paperwork and correspondence for you!</b></p>
            <p>Divorce is never painless, but our Managed Divorce Solutions mean that you won't have to waste hours of your valuable time filling in a never-ending stream of long and confusing forms - and you won't be charged extortionate prices by high street solicitors.</p>
            <p class="highlighted">Why use Managed Divorce?</p>
            <p>Managed Divorce UK is the <b>largest provider</b> of online managed divorce solutions in the UK today. All our services are supervised by <b>case managers</b>.</p>
            <p>During the past 12 years we have <b>established ourselves</b> as one of the most <b>trusted and stable</b> online divorce providers operating in the UK today.</p>
            <p>We were the first managed divorce provider with <b>ISO accreditation</b>, and were one of the <b>founding members of NAPOD</b> - the online divorce industry's first trade association and voluntary regulatory body.</p>
            <p>We currently boast a <b>98.6% customer satisfaction rating</b>.</p>
            <p>We believe we've developed the quickest, simplest, <b>best quality Managed Divorce Solutions available</b> in the UK.</p>
            <p>If, within 30 days of purchase, you find another UK Online Managed Divorce Service that offers the same quality of service and support as Managed Divorce UK, for less money, we'll give you a <b>no-quibble 100% refund GUARANTEED</b> - <b><Link to="/guarantee.html">click here for more</Link></b>.</p>        
        </div>
    );
}

export default QuickSimpleStressFree;