import React, { useState } from "react";
import logo from '../images/managed-divorce-logo-new.svg';
import headBanner from '../images/managed-divorce-banner.jpg';
import {Link} from "react-router-dom";


function Header(props) {

    return (
        <header className="head_main">
            <link rel="stylesheet" type="text/css" charSet="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"/>
            <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"/>
            
            <div className="head_inner container clearfix">
                <div className="sub-menu-header_icon">
                    <div className="menu-toggle">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
                <div className="head_logo">
                    <Link to="/" title="Managed Divorce"><img src={logo} alt="Managed Divorce" title="Managed Divorce"/></Link>
                </div>
                <div className="head_menu">
                    <ul>
                        <li>
                            <Link to="/" title="Home">Home</Link>
                        </li>
                        <li>
                            <Link to="/about.html" title="About Divorce">About Divorce</Link>
                        </li>
                        <li>
                            <Link to="/24-7-case-tracking.html" title="Case Tracking">Case Tracking</Link>
                        </li>
                        <li>
                            <Link to="/frequently-asked-divorce-questions.html" title="FAQ">FAQs</Link>
                        </li>
                        <li>
                            <Link to="/contact-us.html" title="Contact Us">Contact Us</Link>
                        </li>
                        <li className="top_contact_button">
                            <a href="tel:+0800 8030 812" tittle="Call">0800 8030 812</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="head_banner">
                <img src={headBanner} alt="Banner" title="Banner"/>
                <div className="head_bannertext container">
                    <div className="head_bannertext_inner">
                        <p className="home_title">The quickest, most reliable divorce available in the UK today, guaranteed!</p>
                        <p>Revolutionary 24hr tracking service now available!</p>
                        <Link to="/book-consultation.html" title="Book a FREE Call back NOW!">Book a FREE Call back NOW!</Link>
                    </div>                    
                </div>
            </div>            
        </header>
    );
}
export default Header;