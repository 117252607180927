import React from "react";
import { Link } from "react-router-dom";

function UnrivalledService() {
    return (
        <div className="content_wrapper container ul_icon">
            <h1 className="content-title">Unrivalled Service</h1>
            <p class="highlighted">98.6% of customers would recommend Managed Divorce UK to their friend and family</p>
            <p>Managed Divorce is <b>the only online provider of managed divorces that encourages continual customer feedback</b> and posts <b>all customer comments</b> on its website - good or bad!</p>
            <p>By doing this, we constantly improve the service we offer and further raise satisfaction levels.</p>
            <p>Each month we email over 300 new Managed Divorce clients in order to accurately gauge what our customers think of our service. Every response is read, the findings compiled, and <b>we publish the results on our website.</b></p>
            <p>Improving Customer Satisfaction is our number one objective - that's how we currently boast a <b>customer satisfaction rating of 98.6%.</b></p>
            <p>Our surveys show that clients are overwhelmingly satisfied with Managed Divorce's:</p>
            <ul>
                <li>Customer service</li>
                <li>Legal knowledge</li>
                <li>Quality of documentation</li>
                <li>Ease of use of the service</li>
                <li>Speed and efficiency</li>
            </ul>
            <p>Furthermore, all visitors to our website are encouraged to feedback their suggestions via the <Link to="/contact-us.html" title="Contact Us">contact us</Link> page.</p>
            <p>In this way, our company is continually informed of the needs, wishes and suggestions of the public. This enables Managed Divorce to be highly reactive in responding to customer comments, which means <b>our service is constantly measured and improved.</b></p>
            <p><b>No other online managed divorce provider does so much to ensure that the customer is heard, because no other online divorce provider does so much to ensure that customer service needs are fulfilled.</b></p>
        </div>
    );
}

export default UnrivalledService;