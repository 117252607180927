import React from "react";

function Contact() {
    return (
        <div className="content_wrapper container">
            <h1 className="content-title">Contact Us</h1>
            <div className="divider"></div>
            <p className="highlighted">Confused about Divorce?</p>
            <p>Last year, over 3,000 people in the UK used Managed Divorce UK to conduct their divorce proceedings, allowing them to get on with their new, happier lives.</p>
            <p>Our team of specialist divorce consultants can provide a FREE 30-minute, no-obligation consultation at a time convenient to you. Our experts will help you to decide the best course of action and can assist you complete your divorce as quickly and stress-free as possible.</p>
            <div className="contact_us_inner">
              <h4>For free, impartial advice  call us now on freephone:</h4>
              <h2 className="heading_contact highlighted">0800 8030 812</h2>
              <div className="contact-list">
                <div className="contact-item">
                  <table>
                    <tbody>
                      <tr>
                        <td>Email us:</td>
                        <td>
                          <p>General Enquiries: <br/>
                          <a href="mailto:enquiries@managed-divorce.co.uk">enquiries@managed-divorce.co.uk</a></p>
                          <p>Comments &amp; Suggestions: <br/><a href="mailto:webmaster@managed-divorce.co.uk" title="Email">webmaster@managed-divorce.co.uk</a></p>
                        </td>
                      </tr>
                    </tbody>                    
                  </table>
                  <table>
                    <tbody>
                      <tr>
                        <td>Write to us:</td>
                        <td>
                          <p>
                            Managed Divorce UK<br/>
                            21 Old Fields Road<br/>
                            Pencoed<br/>
                            Bridgend<br/>
                            CF35 5LJ
                          </p>
                        </td>
                      </tr>
                    </tbody>                    
                  </table>
                </div>
              </div>
            </div>
        </div>
    );
}

export default Contact;