import React from "react";
import { Link } from "react-router-dom";

function About() {
    return (
        <div className="content_wrapper container">
            <h1 className="content-title">About Divorce</h1>
            <p>To view this process 'at a glance', <Link to="/divorce-at-a-glance.html">click here</Link></p>
            <div className="divider"></div>
            <p className="highlighted">Stage 1 - Application For Divorce</p>
            <p>To get a divorce in England and Wales, you need to show that you have been married for more than a year and that the marriage has “irretrievably broken down”. To do this you need to establish that you have grounds for divorce based on one of the following:</p>
            <ol>
                <li><b>Adultery</b>. Your spouse has committed adultery and you find it intolerable to live together.</li>
                <li><b>Unreasonable Behaviour</b>. Your spouse has behaved in such a way that you cannot reasonably be expected to live together.</li>
                <li><b>Two-Year Separation With Consent</b>. You have been separated for 2 years and your spouse agrees to divorce.</li>
                <li><b>Five-Year Separation</b>. You have been separated for 5 years.</li>
                <li><b>Desertion</b>. Your spouse deserted you more than 2 years ago. We strongly recommend that desertion is avoided. If you want to base your divorce on desertion, please see a solicitor.</li>
            </ol>
            <p>Regardless of which divorce option you choose, Managed Divorce UK’s legal experts will administer and complete all the necessary documentation and processes required to complete your Divorce Application quickly, easily, and with a minimum of stress and discomfort…</p>
            <p>Once you have decided which divorce option is best suited to your circumstances and have submitted all your details using our online questionnaire, your divorce will then progress to <b>Stage 2</b>…</p>
            <div className="divider"></div>
            <p className="highlighted">Stage 2 - Acknowledgement of Service</p>
            <p>Once your Divorce Petition has been completed and sent to your local Divorce Centre, it will be checked by the Courts and issued to your husband/wife.</p>
            <p>In legal proceedings, <b>you</b> are known as the <b>Petitioner</b> and your <b>spouse</b> is known as the <b>Respondent</b>.</p>
            <p>Along with the Divorce documentation, the Respondent will also be sent instructions on how to complete the Acknowledgment of Service documentation.</p>
            <p>Our case managers, using our state-of-the-art case management system, will administer every part of your divorce proceedings for you, from start-to-finish, ensuring your divorce is completed as fast and efficiently as any 'quickie' divorce, allowing you to get on with your new, happier life.</p>
            <p>Once they have completed this simple document and returned it to the Court, your divorce will then progress to <b>Stage 3</b>…</p>
            <div className="divider"></div>
            <p className="highlighted">Stage 3 - Application for Decree Nisi</p>
            <p>In effect, an application for a Decree Nisi is simply the process of asking the Court to place your Divorce Petition and supporting documentation before a Judge in order for him or her to consider whether to grant your divorce.</p>
            <p>Your case manager will complete all relevant Decree Nisi forms and documentation on your behalf; helping you progress to the final stage of your divorce quickly and with a minimum amount of stress.</p>
            <p>Whichever option you choose, Managed Divorce UK’s highly regarded independent panel solicitors and case managers, with a wealth of experience in this field, will complete your Decree Nisi – helping you progress quickly to the final stage of the divorce…</p>
            <div className="divider"></div>
            <p className="highlighted">Stage 4 - Application for Decree Absolute</p>
            <p>After the declaration of your Decree Nisi, you will need to wait a further six weeks and one day, after which you are entitled to apply for a Decree Absolute.</p>
            <p>Following the submission of this application to the Divorce Centre, your case manager or solicitor will then submit your application for Decree Absolute to the Divorce Centre on your behalf. The Court will then declare your marriage dissolved.</p>
            <p>Remember there’s no need to worry because we do it all for you…</p>
            <p>Your case manager will complete all the documentation required and ensure you progress to the final stage of your divorce quickly and with a minimum of stress and discomfort. All you have to do is complete a simple telephone consultation with the minimum of details.</p>
        </div>
    );
}

export default About;