import React from "react";
import { Link } from "react-router-dom";

function Sitemap() {
    return (
        <div className="content_wrapper container">
            <h1 className="content-title">Sitemap</h1>
            <p><strong><Link to="/24-7-case-tracking.html" title="24-7 Case Tracking" className="">24-7 Case Tracking</Link></strong></p>
            <p><strong><Link to="/about.html" title="About Divorce" className="">About Divorce</Link></strong></p>
            <p className="sitemap_desc">The divorce process can be a confusing and unpleasant experience. However, having a proper understanding of why
            certain questions have to be asked and how they effect your divorce will make you better prepared to deal with the process.</p>
            <p><strong><Link to="/contact-us.html" title="Contact Us" className="">Contact Us</Link></strong></p>
            <p className="sitemap_desc">If you have a question that is not answered on our FAQ page or you would like to hear more information
            about our services then contact one of our specially trained divorce advisors.</p>
            <p><strong><Link to="/cost-effective-solution.html" title="Cost effective solution" className="">Cost effective solution</Link></strong></p>
            {/* <p><strong><Link to="/divorce-at-a-glance.html" title="Divorce at a Glance" className="">Divorce at a Glance</Link></strong></p> */}
            <p><strong><Link to="/our-divorce-services.html" title="Divorce Services" className="">Divorce Services</Link></strong></p>
            <p className="/sitemap_desc.html">We offer a comparative divorce service with expert case manage care, as well as a full package service, complete with Clean Break agreement and Lifetime Will</p>
            <p><strong><Link to="/frequently-asked-divorce-questions.html" title="FAQs" className="">FAQs</Link></strong></p>
            <p className="sitemap_desc">If you have questions about the divorce process then see if our FAQs help.</p>
            <p><strong><Link to="/free-ebooks.html" title="Free eBooks" className="">Free eBooks</Link></strong></p>
            <p><strong><Link to="/managed-divorce-plus.html" title="Managed Divorce Plus Service" className="">Managed Divorce Plus Service</Link></strong></p>
            <p><strong><Link to="/managed-divorce.html" title="Managed Divorce Service" className="">Managed Divorce Service</Link></strong></p>
            <p><strong><Link to="/" title="Managed Divorce UK - the UKs favourite online managed divorce service" className="">Managed Divorce UK - the UKs favourite online managed divorce service</Link></strong></p>
            <p className="sitemap_desc">Purchasing an online service to manage your divorce can save you 90% of the cost of a high street solicitor. In a
            managed divorce, a solicitor or case manager will look after your case and ensure your divorce is as hassle-free as possible.</p>
            <p className="sitemap_desc">Our team of experienced and highly qualified professionals are available 6 days a week, 12 hours a day to guide you through your divorce.</p>
            <p className="sitemap_desc">This is your opportunity to decide which divorce plan is best suited to your needs and purchase it using our secure payment system. </p>
            <p><strong><Link to="/terms-and-conditions.html" title="Our Disclaimer" className="">Our Disclaimer</Link></strong></p>
            <p className="sitemap_desc">Disclaimer applicable to a Managed Divorce Agreement.</p>
            <p><strong><Link to="/policy.html" title="Our Privacy Policy" className="">Our Privacy Policy</Link></strong></p>
            <p className="sitemap_desc">Managed Divorce complies with The Data Protection Act 1998.</p>
            <p><strong><Link to="/terms-and-conditions.html" title="Our Terms and Conditions" className="">Our Terms and Conditions</Link></strong></p>
            <p className="sitemap_desc">The following Terms and Conditions apply to all our Managed Divorce Agreements.</p>
            <p><strong><Link to="/3-step-process.html" title="Premier 24HR Service" className="">Premier 24HR Service</Link></strong></p>
            <p><strong><Link to="/quick-simple-stress-free.html" title="Quick, Simple and stress free" className="">Quick, Simple and stress free</Link></strong></p>
            <p><strong><Link to="/resources.html" title="Resources" className="">Resources</Link></strong></p>
            <p className="sitemap_desc">Additional resources that will help to guide you through the divorce process and moving on afterwards.</p>
            <p><strong><Link to="/sitemap.html" title="Sitemap" className="">Sitemap</Link></strong></p>
            <p><strong><Link to="/stable-organisation.html" title="Stable organisation" className="">Stable organisation</Link></strong></p>
            <p><strong><Link to="/contact-us.html" title="Support" className="">Support</Link></strong></p>
            <p className="sitemap_desc">For free impartial advice about initiating the process of an uncontested divorce, you can contact our advice centre.</p>
            <p><strong><Link to="/our-experience-and-expertise.html" title="The UK's largest divorce provider" className="">The UK's largest divorce provider</Link></strong></p>
            <p><strong><Link to="/unrivalled-service.html" titl e="Unrivalled service" className="">Unrivalled service</Link></strong></p>
            <p><strong><Link to="/what-our-customers-say-about-us.html" title="What Our Customers Say" className="">What Our Customers Say</Link></strong></p>
            <p className="sitemap_desc">Before you decide to use our services, read the testimonials of customers who have used our services. </p>
            <p><strong><Link to="/whychooseus.html" title="Why Choose Us?" className="">Why Choose Us?</Link></strong></p>
            <p className="sitemap_desc">The service provided by the Managed Divorce case managers and solicitors is second to none. For one tenth of
            the price of a high street solicitor you can have your divorce looked after by a legal professional or full-time solicitor.</p>
        </div>
    );
}

export default Sitemap;