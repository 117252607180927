import React from "react";
import missionImage from "../../images/our-mission.gif";
import { Link } from "react-router-dom";

function WhyChooseUs() {
    return (
        <div className="content_wrapper container">
            <h1 className="content-title">Why Choose Us</h1>
            <p className="highlighted">Why you should choose Managed Divorce UK</p>
            <p>We at Managed Divorce UK are a group of legal experts committed to making the uncontested divorce process as quick, affordable and <b>stress-free</b> for our clients as possible.</p>
            <p>With highly experienced staff, years of practicing our trade and <b>tens of 1000's of satisfied customers</b>, Managed Divorce UK is proud to have earned a reputation as one of the most respected providers of managed divorce solutions.</p>
            <div className="content-image vertical-margin-30 text-center">
                <img src={missionImage} alt="" title="Mission Image"/>
            </div>
            <p>We believe we have developed the <b>quickest, simplest, best quality divorce solutions available in the UK</b>.</p>
            <p>If, within 30 days of purchase, you find another UK online managed divorce provider that offers the same quality of service, documentation and support as ours, for less money, we will give you a <b>no-quibble 100% refund GUARANTEED</b> - <Link to="/guarantee.html" title="guarantee">click here for more</Link>.</p>        
        </div>
    );
}

export default WhyChooseUs;