import React from "react";
import badgesWhite from "../../images/badges_white.png";

function Guarantee() {
    return (
        <div className="content_wrapper container ul_icon guarantee">
            <h1 className="content-title">WHY CHOOSE US</h1>
            <div class="guarantee_col">
                <div class="guarantee_left">
                    <p class="highlighted">We want you to be 100% happy or 100% refunded!</p>
                    <p>We believe we have developed the quickest, simplest, best quality divorce solution available in the UK.</p>
                    <p>However, we realise that buying on the Internet can make some people feel a bit uneasy.</p>
                    <p>That's why we offer a no-quibble guarantee that completely eliminates any risk, fear or uncertainty you may feel.</p>
                    <p>If for any reason your documents are not accepted by the courts, we will either amend them at our cost or refund your money.</p>
                    <p>Also, if, within 30 days of purchase, you find another online divorce solution that offers the same quality of service, documentation and support as ours, for less money, we will give you a no-quibble 100% refund GUARANTEED.</p>
                    <p>Now, the only risk you face is doing nothing!</p>
                </div>
                <div class="guarantee_right">
                    <img src={badgesWhite} alt="Badges" title="Badges"/>
                </div>
            </div>    
        </div>
    );
}

export default Guarantee;