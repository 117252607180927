import React from "react";
import directionsMap from "../../images/directionsMap.jpg";
function StableOrganisation() {
    return (
        <div className="content_wrapper container">
            <h1 className="content-title">Put your Trust in a Stable Organisation</h1>
            <p>At Managed Divorce*, we realise that anyone can launch a website and rent a backroom office. We also realise that it can be difficult for clients to distinguish between bona fide companies and those operating from ‘virtual offices’, rented accommodation, or even first floor bedrooms in local authority houses!</p>

            <p>Yet if you search for the term 'divorce' on Google, at least two companies will appear on the front page that fit into each of the categories above - and it can be difficult spotting who they are!</p>

            <p>That's why at Managed Divorce*, we've decided to take the guess work out of spotting the real thing by publishing details of the property we operate from. Unlike any of our competitors, we own our own £¾M commercial premises, equipped with the latest state of the art hardware and telecommunications infrastructure.</p>

            <div class="content-image vertical-margin-30 text-center">
                    <img src={directionsMap} alt="Directions Map" title=""/>
            </div>

            <p>No other online managed divorce provider owns and operates from such a modern office complex because no other online managed divorce provider has invested as much in their infrastructure as Managed Divorce.</p>

            <p><small>* Managed Divorce UK is a trading title of Quickie Divorce Limited - the largest providers of online divorces in the UK today.</small></p>
        </div>
    );
}

export default StableOrganisation;