import React from "react";

function CostEffectiveSolution() {
    return (
        <div className="content_wrapper container">
            <h1 className="content-title">Cost Effective Solution</h1>
            <div className="divider"></div>
            <p class="highlighted">Compare Our Prices and Services with Other Online Divorce Providers</p>
            <p>Our managed divorce services should not be confused with inferior options that often lead clients to make expensive and time-consuming mistakes.</p>
            <p>You'll only ever want to buy one divorce package so buy the quickest, most comprehensive, value- for-money solution available in the UK.</p>

            <table class="table table-primary text-center table-colored">
                <tbody><tr class="th-vert-xs">
                    <th class="hidden-xs"><span>Services</span></th>
                    <th><span>Divorce Online</span></th>
                    <th><span>The Law Practice</span></th>
                    <th><span>Fast-track Divorce</span></th>
                    <th><span>Managed Divorce</span></th>
                </tr>
                <tr class="visible-xs">
                    <td colspan="4">Panel Solicitor Service</td>
                </tr>
                <tr>
                    <td class="text-left hidden-xs">Panel Solicitor Service</td>
                    <td><i class="glyphicon glyphicon-ok icon-dark-success"></i></td>
                    <td><i class="glyphicon glyphicon-remove icon-dark-danger"></i></td>
                    <td><i class="glyphicon glyphicon-remove icon-dark-danger"></i></td>
                    <td class="bg-light-success"><i class="glyphicon glyphicon-ok icon-light-success"></i></td>
                </tr>
                <tr class="visible-xs">
                    <td colspan="4">24/7 Case Tracking*</td>
                </tr>
                <tr>
                    <td class="text-left hidden-xs">24/7 Case Tracking*</td>
                    <td><i class="glyphicon glyphicon-remove icon-dark-danger"></i></td>
                    <td><i class="glyphicon glyphicon-remove icon-dark-danger"></i></td>
                    <td><i class="glyphicon glyphicon-remove icon-dark-danger"></i></td>
                    <td class="bg-light-success"><i class="glyphicon glyphicon-ok icon-light-success"></i></td>
                </tr>
                <tr class="visible-xs">
                    <td colspan="4">FREE Phone Support</td>
                </tr>
                <tr>
                    <td class="text-left hidden-xs">FREE Phone Support</td>
                    <td>9am-5:30pm</td>
                    <td>9:30am-5.30pm</td>
                    <td>9.30am-5.30pm</td>
                    <td class="bg-light-success"><strong>8am-9pm**</strong></td>
                </tr>
                <tr class="visible-xs">
                    <td colspan="4">FREE eBooks</td>
                </tr>
                <tr>
                    <td class="text-left hidden-xs">FREE eBooks</td>
                    <td><i class="glyphicon glyphicon-remove icon-dark-danger"></i></td>
                    <td><i class="glyphicon glyphicon-remove icon-dark-danger"></i></td>
                    <td><i class="glyphicon glyphicon-remove icon-dark-danger"></i></td>
                    <td class="bg-light-success"><i class="glyphicon glyphicon-ok icon-light-success"></i></td>
                </tr>
                <tr class="visible-xs">
                    <td colspan="4">PI Insurance</td>
                </tr>
                <tr>
                    <td class="text-left hidden-xs">PI Insurance</td>
                    <td><i class="glyphicon glyphicon-ok icon-dark-success"></i></td>
                    <td><i class="glyphicon glyphicon-ok icon-dark-success"></i></td>
                    <td><i class="glyphicon glyphicon-remove icon-dark-danger"></i></td>
                    <td class="bg-light-success"><i class="glyphicon glyphicon-ok icon-light-success"></i></td>
                </tr>
                <tr class="visible-xs">
                    <td colspan="4">24hr Service Option</td>
                </tr>
                <tr>
                    <td class="text-left hidden-xs">24hr Service Option</td>
                    <td><i class="glyphicon glyphicon-remove icon-dark-danger"></i></td>
                    <td><i class="glyphicon glyphicon-remove icon-dark-danger"></i></td>
                    <td><i class="glyphicon glyphicon-remove icon-dark-danger"></i></td>
                    <td class="bg-light-success"><i class="glyphicon glyphicon-ok icon-light-success"></i></td>
                </tr>
                <tr class="visible-xs">
                    <td colspan="4">FREE Financial Advice</td>
                </tr>
                <tr>
                    <td class="text-left hidden-xs">FREE Financial Advice</td>
                    <td><i class="glyphicon glyphicon-remove icon-dark-danger"></i></td>
                    <td><i class="glyphicon glyphicon-remove icon-dark-danger"></i></td>
                    <td><i class="glyphicon glyphicon-remove icon-dark-danger"></i></td>
                    <td class="bg-light-success"><i class="glyphicon glyphicon-ok icon-light-success"></i></td>
                </tr>
                <tr class="visible-xs">
                    <td colspan="4">10yrs Online Experience</td>
                </tr>
                <tr>
                    <td class="text-left hidden-xs">10yrs Online Experience</td>
                    <td><i class="glyphicon glyphicon-ok icon-dark-success"></i></td>
                    <td><i class="glyphicon glyphicon-remove icon-dark-danger"></i></td>
                    <td><i class="glyphicon glyphicon-remove icon-dark-danger"></i></td>
                    <td class="bg-light-success"><i class="glyphicon glyphicon-ok icon-light-success"></i></td>
                </tr>
                <tr class="visible-xs">
                    <td colspan="4">100,000+ Divorces</td>
                </tr>
                <tr>
                    <td class="text-left hidden-xs">100,000+ Divorces</td>
                    <td><i class="glyphicon glyphicon-remove icon-dark-danger"></i></td>
                    <td><i class="glyphicon glyphicon-remove icon-dark-danger"></i></td>
                    <td><i class="glyphicon glyphicon-remove icon-dark-danger"></i></td>
                    <td class="bg-light-success"><i class="glyphicon glyphicon-ok icon-light-success"></i></td>
                </tr>
                <tr class="visible-xs">
                    <td colspan="4">ISO Certified</td>
                </tr>
                <tr>
                    <td class="text-left hidden-xs">ISO Certified</td>
                    <td><i class="glyphicon glyphicon-remove icon-dark-danger"></i></td>
                    <td><i class="glyphicon glyphicon-remove icon-dark-danger"></i></td>
                    <td><i class="glyphicon glyphicon-ok icon-dark-success"></i></td>
                    <td class="bg-light-success"><i class="glyphicon glyphicon-ok icon-light-success"></i></td>
                </tr>
                <tr class="visible-xs">
                    <td colspan="4">Costs</td>
                </tr>
                <tr>
                    <td class="text-left hidden-xs">Costs</td>
                    <td>£193+</td>
                    <td>£147+</td>
                    <td>£147+</td>
                    <td class="bg-light-success"><span class="white"><strong>£97+</strong></span></td>
                </tr>
            </tbody>
            </table>

            <div class="vertical-margin-30">
                <p><small>* With automated emails and text alerts, document upload/download facilities, messaging and audit log facilities.<br/>**Mon-Thurs 8am-9pm, Fri 8am-5.30pm, Sat 9am-2pm</small></p>

                <p>You can see from the above comparison chart that Managed Divorce UK offers England and Wales' premier service. We are an expert online divorce service - therefore we have considerably fewer overheads than a high-street solicitor – this keeps our costs down to a minimum, allowing us to pass on our savings to our customers.</p>
            </div>
        </div>
    );
}

export default CostEffectiveSolution;