import React from "react";
import { Link } from "react-router-dom";

function ManagedDivorcePage() {
    return (
        <div className="content_wrapper container ul_icon">
            <h1 className="content-title">Managed Divorce Plus</h1>
            <div className="divider"></div>
            <p class="highlighted">Our Managed Divorce Plus Service includes all the benefits of our Managed Divorce Service, plus a Clean Break Agreement and Lifetime Will</p>

            <p><b>Clean Break Agreement</b> (worth £100)</p>

            <p>Clean Break Agreements (or Consent Orders) are <b>the only way to guarantee that financial deals you and your ex-spouse reach are enforceable by law</b>. Clean Break Orders ensure that your ex-partner cannot go back to court to ask for more money or assets post-divorce.</p>

            <p>Clean Break Orders help tie up financial matters such as maintenance and division of property, savings, shares, policies, and pensions. They can even settle child maintenance provided the terms are agreed by both parties and the court.</p>

            <p><b>Lifetime Will</b> (worth £39)</p>

            <p>If you’ve decided to get divorced, then you will probably want to change your Will.</p>

            <p>A valid Will is the only way for you to determine precisely who will inherit your property and who will act as executor. Your Will is a vital legal document, so it is important to ensure that it’s prepared correctly. All our Wills come with <b>FREE</b> updates for life.</p>

            <p><b>Remember, all of this is in addition to our Managed Divorce Plus Service:</b></p>

            <ul>
                <li><b>All</b> Forms filed for you at each stage</li>
                <li><b>All court correspondence</b> dealt with for you</li>
                <li><b>Your entire divorce timetable</b> managed by us</li>
                <li><b>Your own personal case manager</b> from start-to-finish</li>
                <li><b>State-of-the-art case management system</b></li>
                <li><b>FREE Live Support</b> by telephone*, e-mail and live-chat throughout the whole process</li>
                <li><b>FREE Updates &amp; Upgrades</b> – Notification/upgrade of law or documentation requirements</li>
                <li><b>FREE Postage</b> – Unlike other services, we won’t charge extra to post you your divorce forms etc.</li>
                <li><b>FREE Financial Advice</b> – Free advice on any financial aspect as a consequence of divorce</li>
                <li><b>FREE Consultation</b> – If you are uncertain whether our service is right for you please contact us</li>
                <li><b>No-quibble 100% refund GUARANTEE - <Link to="/guarantee.html" title="guarantee">click here for more</Link></b></li>
            </ul>

            <p><small>*Mon-Thurs 8am-9pm, Fri 8am-5.30pm, Sat 9am-2pm</small></p>

            <p>You won’t need the services of a local law firm, or a court appearance, and you won’t need to fill in complicated legal forms or understand solicitors’ jargon; all you’ll need are your personal details and a few minutes of your time.</p>

            <p>Enjoy the security of knowing that a full-time case manager is managing your case from start to finish – from issuing your petition to the delivery of your Decree Absolute.</p>

            <div class="divider"></div>

            <p class="highlighted">Example of Our Simple, Easy to Use Forms <i class="icon-right-dir"></i></p>

            <p>Below is an example of our simple, easy-to-use form that enables us to create your divorce petition. Click on <span class="highlighted">[help]</span> for an explanation of how to answer the relevant question.</p>


            


            <div class="divider"></div>

            <p class="highlighted">Compare Our Prices and Services with Other Online Divorce Providers</p>

            <p>Our managed divorce services should not be confused with inferior options that often lead clients to make expensive and time-consuming mistakes.</p>

            <p>You'll only ever want to buy one divorce package so buy the quickest, most comprehensive, value- for-money solution available in the UK.</p>

            <table class="table table-primary text-center table-colored">
            <tbody><tr class="th-vert-xs">
            <th class="hidden-xs"><span>Services</span></th>
            <th><span>Divorce Online</span></th>
            <th><span>The Law Practice</span></th>
            <th><span>Fast-track Divorce</span></th>
            <th><span>Managed Divorce</span></th>
            </tr>
            <tr class="visible-xs">
            <td colspan="4">Panel Solicitor Service</td>
            </tr>
            <tr>
            <td class="text-left hidden-xs">Panel Solicitor Service</td>
            <td><i class="glyphicon glyphicon-ok icon-dark-success"></i></td>
            <td><i class="glyphicon glyphicon-remove icon-dark-danger"></i></td>
            <td><i class="glyphicon glyphicon-remove icon-dark-danger"></i></td>
            <td class="bg-light-success"><i class="glyphicon glyphicon-ok icon-light-success"></i></td>
            </tr>
            <tr class="visible-xs">
            <td colspan="4">24/7 Case Tracking*</td>
            </tr>
            <tr>
            <td class="text-left hidden-xs">24/7 Case Tracking*</td>
            <td><i class="glyphicon glyphicon-remove icon-dark-danger"></i></td>
            <td><i class="glyphicon glyphicon-remove icon-dark-danger"></i></td>
            <td><i class="glyphicon glyphicon-remove icon-dark-danger"></i></td>
            <td class="bg-light-success"><i class="glyphicon glyphicon-ok icon-light-success"></i></td>
            </tr>
            <tr class="visible-xs">
            <td colspan="4">FREE Phone Support</td>
            </tr>
            <tr>
            <td class="text-left hidden-xs">FREE Phone Support</td>
            <td>9am-5:30pm</td>
            <td>9:30am-5.30pm</td>
            <td>9.30am-5.30pm</td>
            <td class="bg-light-success"><strong>8am-9pm**</strong></td>
            </tr>
            <tr class="visible-xs">
            <td colspan="4">FREE eBooks</td>
            </tr>
            <tr>
            <td class="text-left hidden-xs">FREE eBooks</td>
            <td><i class="glyphicon glyphicon-remove icon-dark-danger"></i></td>
            <td><i class="glyphicon glyphicon-remove icon-dark-danger"></i></td>
            <td><i class="glyphicon glyphicon-remove icon-dark-danger"></i></td>
            <td class="bg-light-success"><i class="glyphicon glyphicon-ok icon-light-success"></i></td>
            </tr>
            <tr class="visible-xs">
            <td colspan="4">PI Insurance</td>
            </tr>
            <tr>
            <td class="text-left hidden-xs">PI Insurance</td>
            <td><i class="glyphicon glyphicon-ok icon-dark-success"></i></td>
            <td><i class="glyphicon glyphicon-ok icon-dark-success"></i></td>
            <td><i class="glyphicon glyphicon-remove icon-dark-danger"></i></td>
            <td class="bg-light-success"><i class="glyphicon glyphicon-ok icon-light-success"></i></td>
            </tr>
            <tr class="visible-xs">
            <td colspan="4">24hr Service Option</td>
            </tr>
            <tr>
            <td class="text-left hidden-xs">24hr Service Option</td>
            <td><i class="glyphicon glyphicon-remove icon-dark-danger"></i></td>
            <td><i class="glyphicon glyphicon-remove icon-dark-danger"></i></td>
            <td><i class="glyphicon glyphicon-remove icon-dark-danger"></i></td>
            <td class="bg-light-success"><i class="glyphicon glyphicon-ok icon-light-success"></i></td>
            </tr>
            <tr class="visible-xs">
            <td colspan="4">FREE Financial Advice</td>
            </tr>
            <tr>
            <td class="text-left hidden-xs">FREE Financial Advice</td>
            <td><i class="glyphicon glyphicon-remove icon-dark-danger"></i></td>
            <td><i class="glyphicon glyphicon-remove icon-dark-danger"></i></td>
            <td><i class="glyphicon glyphicon-remove icon-dark-danger"></i></td>
            <td class="bg-light-success"><i class="glyphicon glyphicon-ok icon-light-success"></i></td>
            </tr>
            <tr class="visible-xs">
            <td colspan="4">10yrs Online Experience</td>
            </tr>
            <tr>
            <td class="text-left hidden-xs">10yrs Online Experience</td>
            <td><i class="glyphicon glyphicon-ok icon-dark-success"></i></td>
            <td><i class="glyphicon glyphicon-remove icon-dark-danger"></i></td>
            <td><i class="glyphicon glyphicon-remove icon-dark-danger"></i></td>
            <td class="bg-light-success"><i class="glyphicon glyphicon-ok icon-light-success"></i></td>
            </tr>
            <tr class="visible-xs">
            <td colspan="4">100,000+ Divorces</td>
            </tr>
            <tr>
            <td class="text-left hidden-xs">100,000+ Divorces</td>
            <td><i class="glyphicon glyphicon-remove icon-dark-danger"></i></td>
            <td><i class="glyphicon glyphicon-remove icon-dark-danger"></i></td>
            <td><i class="glyphicon glyphicon-remove icon-dark-danger"></i></td>
            <td class="bg-light-success"><i class="glyphicon glyphicon-ok icon-light-success"></i></td>
            </tr>
            <tr class="visible-xs">
            <td colspan="4">ISO Certified</td>
            </tr>
            <tr>
            <td class="text-left hidden-xs">ISO Certified</td>
            <td><i class="glyphicon glyphicon-remove icon-dark-danger"></i></td>
            <td><i class="glyphicon glyphicon-remove icon-dark-danger"></i></td>
            <td><i class="glyphicon glyphicon-ok icon-dark-success"></i></td>
            <td class="bg-light-success"><i class="glyphicon glyphicon-ok icon-light-success"></i></td>
            </tr>
            <tr class="visible-xs">
            <td colspan="4">Costs</td>
            </tr>
            <tr>
            <td class="text-left hidden-xs">Costs</td>
            <td>£193+</td>
            <td>£147+</td>
            <td>£147+</td>
            <td class="bg-light-success"><span class="white"><strong>£97+</strong></span></td>
            </tr>
            </tbody></table>

            <div class="vertical-margin-30">
            <p><small>* With automated emails and text alerts, document upload/download facilities, messaging and audit log facilities.<br/>**Mon-Thurs 8am-9pm, Fri 8am-5.30pm, Sat 9am-2pm</small></p>

            <p>You can see from the above comparison chart that Managed Divorce UK offers England and Wales' premier service. We are an expert online divorce service – therefore we have considerably fewer overheads than a high-street solicitor – this keeps our costs down to a minimum, allowing us to pass on our savings to our customers.</p>
            </div>

            <div class="divider"></div>

            <p class="highlighted">See What You Can Save by Using Managed-Divorce</p>

            <p>A <b>High Street solicitor</b> may charge you <b>£100 per hour</b> (plus VAT):</p>

            <ul>
            <li>Solicitors fees (incl. VAT) £940.00</li>
            <li>Court Fees are applicable *</li>
            <li>Total Costs <b>£940.00 + Court Fees</b> *</li>
            </ul>

            <p>Compare the cost of <b>our divorce</b>:</p>

            <ul>
            <li>Our divorce options: £167</li>
            <li>Court Fees are applicable *</li>
            <li>Total Costs <b>£167 + Court Fees</b> *</li>
            </ul>

            <p><b>ALSO</b> – Receive ‘The Essential Guide to Divorce’ by Dr Charlotte B Price (normally £12.99)</p>

            <p><b>AND</b> – ‘The Essential Guide to Consent Orders’ by Dr Charlotte B Price (normally £12.99)</p>

            <p class="highlighted"><b>BOTH ABSOLUTELY FREE</b></p>

            <p><small>* These payments are fixed and must be paid to the court. You may be required to pay an additional Court Fee for lodging your Petition. The amount payable depends on your individual circumstances, but should not exceed £593. <Link to="/frequently-asked-divorce-questions" title="FAQ">See FAQs</Link>.</small></p>          
        </div>
    );
}

export default ManagedDivorcePage;