import React from "react";
import {Link} from "react-router-dom";
import footerLogo from '../images/managed-divorce-logo-footer.svg';

function Footer(){
    return(
        <footer className="footer_main">            
                <div className="foot_list">
                    <div className="container clearfix">
                        <div className="foot_list_item social_icons">
                            <Link to="">
                                <img src={footerLogo} alt="Managed-Divorce" title="Managed-Divorce" border="0"/>
                            </Link>
                            <p>Managed Divorce UK is dedicated to providing the quickest and most cost effective divorce solutions and provides its services for uncontested divorces in England and Wales only.</p>
                            <ul>
                                {/* <li className="facebook">
                                    <Link to="" className="" title="Facebook">Facebook</Link>
                                </li>
                                <li className="instagram">
                                    <Link to="" className="" title="Instagram">Instagram</Link>
                                </li> */}
                                <li className="twitter">
                                    <Link to="" className="" title="Twitter">Twitter</Link>
                                </li>
                                {/* <li className="linkedin">
                                    <Link to="" className="" title="Linkedin">Linkedin</Link>
                                </li> */}
                            </ul>
                        </div>
                        <div className="foot_list_item">
                            <h3>Company</h3>
                            <ul>
                                <li><Link to="/about.html" title="about">About Us</Link></li>
                                <li><Link to="/whychooseus.html" title="whychooseus">Why Choose Us</Link></li>
                                <li><Link to="/free-ebooks.html" title="Free eBooks">Free eBooks</Link></li>
                                <li><Link to="/24-7-case-tracking.html" title="24/7 Case Tracking">24/7 Case Tracking</Link></li>
                                <li><Link to="/contact-us.html" title="Contact Us">Contact Us</Link></li>
                                <li><a href="https://tracker.managed-divorce.co.uk/login" title="Client Login">Sign In</a></li>
                            </ul>
                        </div>
                        <div className="foot_list_item">
                            <h3>Other Pages</h3>
                            <ul>
                                <li><Link to="/policy.html" title="policy">Privacy Policy</Link></li>
                                <li><Link to="/3-step-process.html" title="premierservice">Premier Service</Link></li>
                                <li><Link to="/terms-and-conditions.html" title="terms and conditions">Terms of Service</Link></li>
                                <li><Link to="/sitemap.html" title="sitemap">Sitemap</Link></li>
                                <li><Link to="/resources.html" title="Resources">Resources</Link></li>
                            </ul>
                        </div>
                        <div className="foot_list_item">
                            <h3>For More Info</h3>
                            <p><span>Phone:</span>0800 8030 812</p>
                            <p><span>Email:</span>enquiries@managed-divorce.co.uk</p>
                            <p><span>Address:</span><span>Bridgend, CF35 5LJ</span>21 Old Fields Road, Pencoed,</p>
                        </div>
                    </div>
                </div>
                <div className="foot_copy">
                    <div className="clearfix">
                        <p>© 2022 Managed Divorce | All rights are reserved | Developed by MOBO</p>
                    </div>
                </div>
        </footer>
    );
}
export default Footer;