import React from "react";
import { Link } from "react-router-dom";

function Resources() {
    return (
        <div className="content_wrapper container">
            <h1 class="content-title">Resources</h1>
            <Link to="http://www.ondivorce.co.uk/" target="_blank" title="OnDivorce">OnDivorce</Link>
            <p>An excellent online resource with a very active online community to offer mutual support and share experiences on divorce.</p>
            <Link to="https://www.womansdivorce.com/" target="_blank" title="Divorce Advice For Women">Divorce Advice For Women</Link>
            <p>Articles, advice, and information to help women survive the divorce process and rebuild their lives.</p>          
        </div>
    );
}

export default Resources;