import React from "react";
import { Link } from "react-router-dom";
import privacy_policy from "../../resources/downloads/privacy_policy.pdf";
import complaints_procedure from "../../resources/downloads/complaints_procedure.pdf";
import terms_and_conditions from "../../resources/downloads/terms_and_conditions.pdf";
import disclaimer from "../../resources/downloads/disclaimer.pdf";

function Policy() {
    return (
        <div className="content_wrapper container">
            <h1 className="content-title">Privacy Policy</h1>
            <p>Managed Divorce a registered data controller under the Data Protection Act 1998 No. Z257203X.</p>
            <p>Managed Divorce is dedicated to protecting your privacy and will use the information that we collect about you lawfully and in accordance with the Data Protection Act 1998.</p>
            <p>We collect information about you for two reasons: to process your order and provide you with the service that you have purchased, and to provide you with the best possible service.</p>
            <p>Representatives of Managed Divorce will only contact you in relation to the services that you have purchased or in order to discuss related services which we believe are likely to be of interest to you. You will be under no obligation to proceed with any service that we may suggest and will inform you of any referral fee that we may receive.</p>
            <p>We will not pass your email address or any other contact details on to unrelated third parties unless you have indicated that you are happy for us to do so and to keep you informed of offers from other companies.</p>
            <p>The information that we may collect in order to prepare documents on your behalf will be used solely in order to fulfil your order and will not be passed on to any third parties. Any correspondence that we may send to you will relate solely to the fulfilment of the service that you have purchased or improvement of it.</p>
            <p>We will retain electronic copies of the documents that we prepare for you, as well as copies of electronic correspondence that is exchanged between us, only. We will remove this data upon your request provided the service that you have purchased has been fulfilled in its entirety.</p>
            <p>We will not retain any copies of hard documents or correspondence all of which will be shredded and disposed of securely via a certificated secure waste disposal process. You should ensure that you retain a copy of any document that you send us in case you should require them in the future.</p>
            <p>In order to process an order, Managed Divorce will be required to collect the following information:</p>
            <ol>
                    <li>Your name;</li>
                    <li>Your address;</li>
                    <li>Your phone number;</li>
                    <li>Your email address; and</li>
                    <li>Your credit/debit card details.*</li>
                </ol>
            <p><small>*All transactions are handled by HSBC secure e-payments and Managed Divorce will never retain any information past the point of sale.</small></p>
            <p>In order for us to prepare documents on your behalf, we will need to collect personal information about yourself, your spouse and any children that were born to you or that lived with you and your spouse as part of your family during your marriage. Whilst additional sensitive information may be required in order to complete your documentation, we will not collect this from you without first having obtained your express consent to do so.</p>
            <p>Managed Divorce is dedicated to ensuring that all of the information that we hold in relation to our customers is accurate and up to date. You can check any of the information that we hold about you by emailing us and, should you find any inaccuracies, will delete or correct it promptly.</p>
            <p>We will obtain your consent beforehand should we wish to transfer your information outside of the EEA (European Economic Area).</p>
            <p>When you visit or interact with our site, we may use ‘cookies’ in order to store information and provide you with a faster, safer and better experience as well as for advertising purposes. Cookies can be disabled or removed by tools that are available in most commercial browsers.</p>
            <p>Should you have any questions about our privacy policy or how we use cookies, please email us at <a href="mailto:support@Managed-divorce.com" title="Mail">support@Managed-divorce.com</a>.</p>
            <h3 className="highlighted">Cookies</h3>
            <p className="highlighted">What are cookies?</p>
            <p>Like the vast majority of websites, we use small data files that are placed onto your devices (computer, tablet, mobile phone etc.) which allow us to record specific types of information whenever you visit and interact with our website.</p>
            <p>These files are used in order to help you better navigate through the pages of our website, to remember your individual preferences, to track information about your visits and, ultimately, in order to improve your overall experience. Additionally, these files can be used in order to ensure that the adverts that you see online are more likely to be of interest to you.</p>
            <p>You are, in accordance with EU Cookie Law, required to provide us with permission to use cookies before we may place them on your device. Please note that, if you do not provide us with these permissions, certain parts of our website may not function correctly.</p>
            <h3 className="highlighted">Types of Cookie</h3>
            <p className="highlighted">Persistent Cookies</p>
            <p>These cookies will remain present on a device for a length of time specified within the cookie itself. These cookies remember a user’s individual preferences and restore them when the user revisits the relevant website.</p>
            <p className="highlighted">Session Cookies</p>
            <p>Also known as persistent cookies, these files allow websites to recall which pages you have visited and what information you may have entered in order to ensure that you do not need to enter it again and that you may move from page to page as quickly as possible.</p>
            <p>Sessions cookies are entirely temporary and are removed from devices as soon as a browsing sessions ends.</p>
            <p className="highlighted">Essential Cookies</p>
            <p>These cookies are vital as they keep our website functional and enable us to provide clients with the services that they have purchased from us. As these cookies are essential to the functionality of our website, we are not required to collect your consent to place them on your device and, by using our website, you consent to these files being placed on your device.</p>
            <p>These cookies are used to ensure that our site is functional and that we may provide clients with the services that they have purchased only. These cookies provide no information to any third parties, do not track behaviour and are not used to serve adverts or any other content on users of our site on unrelated websites.</p>
            <p className="highlighted">Performance Cookies</p>
            <p>Performance cookies collect anonymous information on how people use our website which we, in turn, use in order to improve our website and services. Examples of this may include determining which pages of our site are visited the most frequently or to test different versions of our site. All of the information that is collected through these cookies is entirely anonymous and cannot be used in order to identify individual visitors.</p>
            <p>In particular, Managed Divorce uses cookies supplied to us by Google that are designed to enable us to utilise its Analytics tool. These are as follows:</p>
            <ol>
                <li>__utma</li>
                <li>__utmt</li>
                <li>__utmb</li>
                <li>__utmc</li>
                <li>__utmz</li>
                <li>__utmv</li>
            </ol>
            <p>Each of these cookies collects information about how visitors use our site. We use this information in order to compile reports and help us make improvements to our site. The cookies listed above collect information in an anonymous form, including the number of visitors to the site, how visitors have found our site and the pages they visited. Please click <a href="https://support.google.com/analytics/answer/6004245" target="_blank" rel="nofollow noreferrer">here</a> to view Google Analytics Privacy Policy and <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="nofollow noreferrer">here</a> to learn how to opt out of Google Analytics.</p>
            <p className="highlighted">Advertising Cookies</p>
            <p>Managed Divorce uses remarketing cookies provided by Google AdWords and Google subsidiary Doubleclick in order to trigger adverts concerning products and/or services which are likely to be of interest to you across numerous websites. These cookies are placed on your machine when you visit our website and neither identify you nor provide any individual or website access to your device/s.  Should you wish to do so, you may opt out of Google’s and its subsidiaries’ remarketing cookies <a href="http://www.google.com/settings/ads?hl=en&amp;sig=ACi0TCgUK7R4firCdFOr1ed0L4U0BWmwMI8OmlBSX6z8U6SzQ58ztiZ_MYL-fQ4XCbIrRlo1KPa32jX59uJOTk1HEpcZIB-j3MBNUVxcaJ4iyx8Wfzcob1nC3_nRlQ-NJyeYsugPkO8HaiQVdOt7HwMV8bZinU16euATyKaooxEjbyl-pC6Ckbfm-prH9P38PqbaSuDA0zHa" target="_blank" rel="nofollow noreferrer">here</a>.</p>
            <p>Managed Divorce uses the following cookies in order to trigger adverts offering goods/services that may be of interest to you:</p>
            <ol>
                <li>NID</li>
                <li>PREF</li>
                <li>SID</li>
            </ol>
            <table className="table table-bordered-none table-vert-middle">
                <tbody>
                    <tr>
                        <td width="200px"><b>Terms and Conditions</b></td>
                        <td><a className="btn btn-danger" href={terms_and_conditions} title="Download" target="_blank" download>Download</a></td>
                    </tr>
                    <tr>
                        <td><b>Complaints Procedure</b></td>
                        <td><a className="btn btn-danger" href={complaints_procedure} title="Download" target="_blank" download>Download</a></td>
                    </tr>
                    <tr>
                        <td><b>Privacy Policy</b></td>
                        <td><a className="btn btn-danger" href={privacy_policy} title="Download" target="_blank" download>Download</a></td>
                    </tr>
                    <tr>
                        <td><b>Disclaimer</b></td>
                        <td><a className="btn btn-danger" href={disclaimer} title="Download" target="_blank" download>Download</a></td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default Policy;